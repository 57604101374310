// Entry point for the build script in your package.json

import { start as startRails } from "@rails/ujs";
startRails();
// require("@rails/activestorage").start();
// require("channels");

// Window animation
import WindowAnimation from "./lib/WindowAnimation";
WindowAnimation.start();

// Stimulus
import { Application } from "stimulus";
import FigureOverlayController from "./controllers/FigureOverlayController";
import MobileNavController from "./controllers/MobileNavController";
const application = Application.start();
application.register("figure-overlay", FigureOverlayController);
application.register("mobile-nav", MobileNavController);

// // React
// import * as Components from "./components";
// const ReactRailsUJS = require("react_ujs");
// ReactRailsUJS.getConstructor = (className) => Components[className];
